import React from "react"
import { graphql } from "gatsby"
import { Disqus } from "gatsby-plugin-disqus"
import styled from "styled-components"

import Layout from "../components/layout"
import BlogPostFull from "../components/post/BlogPostFull"
import * as Colors from "../atomics/colors"
import Link from "../atomics/Link"
import ArrowLeftIcon from "../atomics/assets/lnr-arrow-left-heliotrope.svg"
import SEO from "../components/seo"

const GoBackAnchor = styled(Link)`
  display: inline-block;
  font-size: 14px;
  color: ${Colors.heliotrope};
  font-weight: 600;
  > img {
    margin-right: 8px;
  }

  @media only screen and (max-width: 800px) {
    margin-bottom: 32px;
  }

  @media only screen and (min-width: 800px) {
    margin-bottom: 44px;
  }
`

const PostPage = (props) => {
  const {
    pageContext: { slug },
    data: { contentfulBlogPost: post },
  } = props

  return (
    <Layout>
      <SEO
        title={post.title}
        keywords={post.keywords}
        description={post.metaDescription}
      />
      <BlogPostFull
        slug={slug}
        headerData={{
          metadata: {
            dateString: post.publishedDate,
            minutesRead: post.content.childMarkdownRemark.timeToRead,
            category: post.category,
          },
          title: post.title,
          tags: post.tags,
        }}
        imgUrl={post.image.fluid}
        contentHtml={post.content.childMarkdownRemark.html}
      />
      <Disqus
        config={{
          identifier: post.slug,
          title: post.title,
        }}
      />
      <GoBackAnchor
        to="/"
        label="All posts"
      >
        <img src={ArrowLeftIcon} alt="Go back" />
        <span>Go back to all blog posts</span>
      </GoBackAnchor>
    </Layout>
  )
}

export const query = graphql`
  query ContentfulBlogPost($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishedDate (formatString: "MMM D, YYYY")
      content {
        childMarkdownRemark {
          timeToRead
          html
        }
      }
      category {
        name
        slug
      }
      tags {
        name
        slug
      }
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      metaDescription
      keywords
    }
  }
`

export default PostPage