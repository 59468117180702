import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"

import BlogPostContainer from "./BlogPostContainer"
import BlogPostHeader from "./BlogPostHeader"
import PostImageContainer from "./PostImageContainer"
import Link from "../../atomics/Link"
import BlogPostContent from "./BlogPostContent"

const BlogPostFull = (props) => {
  const { headerData, slug, imgUrl, contentHtml } = props

  return (
    <BlogPostContainer variant="post-full">
      <BlogPostHeader data={headerData} slug={slug} />
      <Link
        to={`/posts/${slug}`}
        className="featured-image"
        label={headerData.title}
      >
        <PostImageContainer className="post-thumbnail">
          <Img fluid={imgUrl} imgStyle={{ objectFit: "cover" }} />
        </PostImageContainer>
      </Link>
      <BlogPostContent
        className="post-content"
        dangerouslySetInnerHTML={{ __html: contentHtml }}
      />
    </BlogPostContainer >
  )
}

BlogPostFull.propTypes = {
  headerData: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  imgUrl: PropTypes.object.isRequired,
  contentHtml: PropTypes.string.isRequired,
}

export default BlogPostFull